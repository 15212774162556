import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/Reveal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/about.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/app-download-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/blog-details-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/blog-details-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/blog-eprocure-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/blog-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/blogs-page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/bussiness-network-tabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/bussiness-type-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/cart-items-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/category-box.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/contact.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/content-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/customer-feedback-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/details-customer-feedback.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/discover-all-layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/document-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/expert-cards-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/facts-numbers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/featured-products-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/gallery.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/hero-section-home.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/hero-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/info-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/market-card-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/market-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/market-tabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/marketplace-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/marketplace-image-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/network-img-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/network-tabs-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/popular-suppliers-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/procurement-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/product-detail-accordian.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/product-icon-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/product-image-gallery.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/product-img.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/product-list-slider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/public-rfp-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/quick-tour.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/recently-viewed-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/services-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/services-slider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/suggested-expert-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/suggested-supplier-buyer-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/widgets/supplier-nav-tabs.tsx");
